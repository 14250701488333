<template>
  <div class="px-5">
    <Form
      title="Crear entrenador"
      ref="coachForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      loading: false,
      form: {
        photos: [{}]
      },
      visible: false
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    save() {
      let form = this.$refs.coachForm.form

      this.loading = true

      this.$store
        .dispatch("global/create", {
          payload: {
            id_academy: form.id_academy,
            id_carnet_type: form.id_carnet_type,
            first_name: form.first_name,
            last_name: form.last_name,
            dni: form.dni,
            address: form.address,
            gender: form.gender,
            phone: form.phone,
            email: form.email,
            instagram: form.instagram,
            profession: form.profession,
            url_profile_picture: form.url_profile_picture,
            url_dni_picture: form.url_dni_picture,
            url_carnet_picture: form.url_carnet_picture,
            active: !this.visible ? 0 : 1
          },
          route: "/coach/save",
          module: "coach"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          if ([200, 201].includes(response.code)) {
            this.loading = false
            this.$router.push("/entrenadores")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {}
}
</script>
